// eslint-disable-next-line no-restricted-imports
import Link, { LinkProps } from 'next/link'
import React, { FC } from 'react'
import translation from '../../../../../translation.json'
import { useTranslation } from 'src/@legacy/@core/hooks/UserTranslation'
import { match, compile } from 'path-to-regexp'
import { getJobQueryFromSEOPathNameV2, toSEOPathNameV2 } from '../../utilities/SEOUntilities'
import { removeEndSplash } from '../../utilities/StringUtils'
import { useRouter } from 'next/router'
import queryString from 'query-string'
const languageMap = translation.reduce((result, item) => ({ ...result, [item.vi]: 'vi', [item.en]: 'en' }), {})
const defaultArray = translation.map((item) => item.default)

const LinkResolverHandleMap = {
  JobSearchSlugName: (pathName, lang) => {
    const { search, locationId, categoryId } = getJobQueryFromSEOPathNameV2(pathName)
    return toSEOPathNameV2({ search, locationId, categoryId }, lang)
  }
}
export const translateRouterHref = (href: string, lang: string) => {
  const [pathName, searchQuery] = href.split('?')

  const translateRouter = translation.find((tranR) => {
    return match(tranR.default)(pathName) || (tranR.vi && match(tranR.vi)(pathName)) || (tranR.en && match(tranR.en)(pathName))
  })
  if (translateRouter) {
    const matcherSource =
      match<any>(translateRouter.default)(pathName) ||
      (translateRouter.en && match<any>(translateRouter.en)(pathName)) ||
      (translateRouter.vi && match<any>(translateRouter.vi)(pathName))
    if (!translateRouter[lang]) {
      return href
    }
    //@ts-ignore
    const translateHref = compile(translateRouter[lang])(matcherSource.params)
    return translateHref + (searchQuery ? `?${searchQuery}` : ``)
  }
  return href
}
export const getTranslateConfig = (href: string, lang: string): { href: string; defaultPath?: string } => {
  const [pathName, searchQuery] = href.split('?')

  const translateRouter = translation.find((tranR) => {
    return match(tranR.default)(pathName) || (tranR.vi && match(tranR.vi)(pathName)) || (tranR.en && match(tranR.en)(pathName))
  })
  if (translateRouter) {
    const matcherSource =
      match<any>(translateRouter.default)(pathName) ||
      (translateRouter.en && match<any>(translateRouter.en)(pathName)) ||
      (translateRouter.vi && match<any>(translateRouter.vi)(pathName))
    if (!translateRouter[lang]) {
      return { href, defaultPath: translateRouter.default + (searchQuery ? `?${searchQuery}` : ``) }
    }
    //@ts-ignore
    const translateHref = compile(translateRouter[lang])(matcherSource.params)
    return {
      href: translateHref + (searchQuery ? `?${searchQuery}` : ``),
      defaultPath: translateRouter.default + (searchQuery ? `?${searchQuery}` : ``)
    }
  }
  return { href }
}
export const getTranslatableLinks = (pathName: string) => {
  const translateRouter = translation.find((tranR) => {
    return match(tranR.default)(pathName) || (tranR.vi && match(tranR.vi)(pathName)) || (tranR.en && match(tranR.en)(pathName))
  })
  if (translateRouter) {
    const matcherSource =
      match<any>(translateRouter.default)(pathName) ||
      (translateRouter.en && match<any>(translateRouter.en)(pathName)) ||
      (translateRouter.vi && match<any>(translateRouter.vi)(pathName))
    return Object.keys(translateRouter).map((lang) => {
      //@ts-ignore
      return { lang, url: `${removeEndSplash(compile(translateRouter[lang])(matcherSource.params))}` }
    })
  }
  return []
}

const mergeUTLQuery = (href, query) => {
  const [url, hquery] = href.split('?')
  const fullQuery = { ...queryString.parse(hquery), ...query }
  return `${url}${Object.values(fullQuery).length > 0 ? `?${queryString.stringify(fullQuery)}` : ``}`
}
export const AppLink: FC<LinkProps & { disable?: boolean; hardReload?: boolean; keepUTM?: boolean }> = (props) => {
  const { currentLanguage } = useTranslation()
  const router = useRouter()
  const href = props.keepUTM ? mergeUTLQuery(props.href, queryString.parse(router.asPath.split('?')[1])) : props.href
  const translateHref = translateRouterHref(href as string, currentLanguage)
  if (props.disable) {
    return <>{props.children}</>
  }

  return props.hardReload && props.children ? (
    <>
      {React.Children.map(props.children, (child) => {
        // Checking isValidElement is the safe way and avoids a typescript
        // error too.
        if (React.isValidElement(child)) {
          return React.cloneElement(child, { href: translateHref || href })
        }
        return child
      })}
    </>
  ) : (
    <Link prefetch={false} {...props} as={translateHref} locale={false} />
  )
}
